
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  data: () => ({
    contacts: {
      "Единый e-mail для общих вопросов": ["info@rif.ru"],
      "Работа со спикерами": ["prog@rif.ru"],
      Партнерство: ["partners@rif.ru"],
      "Информационное сотрудничество, аккредитация СМИ": ["pr@rif.ru"],
    } as Record<string, string[]>,
  }),

  computed: {
    ...mapState("app", ["event"]),
    links (): Record<string, string> {
      return pickBy(this.event?.links || {}, Boolean)
    },
  },
})
