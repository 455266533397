
import Vue from "vue"

interface Point {
  n?: number
  title: string
  desc?: string
  x: number
  y: number
  icon?: string
  show?: boolean
  active?: boolean
}

export default Vue.extend({
  props: {
    noBg: { type: Boolean, default: false },
  },

  data: () => ({
    points: [
      { n: 1, title: "Главная сцена", x: 16, y: 5 },
      { n: 2, title: "РИФ.Кафе", x: 17, y: 16, icon: "cafe" },
      {
        n: 3,
        title: "Главный корпус",
        desc: "залы 1,2,3. РИФ.Инфо",
        x: 26,
        y: 6,
        show: true,
      },
      { n: 4, title: "РИФ.FUN", x: 18, y: 24 },
      {
        n: 5,
        title: "Шатер партнера",
        x: 25,
        y: 17,
      },
      {
        n: 6,
        title: "Шатер партнера",
        x: 29,
        y: 28,
      },
      { n: 7, title: "Шатер партнера", x: 34, y: 27 },
      { n: 8, title: "Залы 4, 5", x: 41, y: 43 },
      { n: 9, title: "Залы 6, 7", x: 37, y: 48 },
      { n: 10, title: "Парковка", x: 51, y: 70, icon: "parking" },
      { n: 11, title: "Регистрация", x: 55.5, y: 85 },
      { n: 12, title: "Парковка каршеринга", x: 51, y: 76, icon: "belkacar" },
      { n: 13, title: "РИФ.Лаунж", x: 30, y: 21 },
      { n: 14, title: "Уличные активности", x: 23, y: 24 },
      { n: 15, title: "Шатер партнера", x: 36, y: 32 },
      { n: 16, title: "Бар пумба", x: 26, y: 71 },
      { title: "Корпус 1", x: 52, y: 17, show: true },
      { title: "Корпус 2", x: 66, y: 29, show: true },
      { title: "Корпус 3", x: 80, y: 50, show: true },
      { title: "Корпус 6", x: 39, y: 39, show: true },
    ] as Point[],

    isNavItemHovered: false,
    mapVisible: false,
  }),

  methods: {
    onNavItemMouseOver (i: number) {
      this.isNavItemHovered = true
      this.$set(this.points, i, { ...this.points[i], active: true })
    },

    onNavItemMouseLeave (i: number) {
      this.isNavItemHovered = false
      this.$set(this.points, i, { ...this.points[i], active: false })
    },

    onVisibilityChanged (visible: boolean) {
      if (visible) this.mapVisible = true
    },
  },
})
