
import { mapState, mapGetters } from "vuex"
import layoutMixin from "@/mixins/layout"

export default layoutMixin.extend({
  middleware: "auth",

  data: () => ({
    isMounted: false,
  }),

  mounted () {
    this.isMounted = true
  },

  computed: {
    ...mapState("user", ["unreadMessagesCount"]),
    ...mapGetters("user", [
      "user",
      "isPayable",
      "isPaid",
      "isParkingAllowed",
      "isBadgeVisible",
    ]),

    tabs () {
      const tabs = [
        "calendar",
        // "chat",
      ]
      if (this.isParkingAllowed) tabs.unshift("parking")
      if (this.isPayable) tabs.unshift("payment")
      if (this.isBadgeVisible) tabs.push("badge")
      return tabs
    },
  },
})
