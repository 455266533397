
import Vue from "vue"

export default Vue.extend({
  props: {
    settings: { type: Object, default: () => ({}) },
  },

  mounted () { this.$nuxt.$on("open-login", this.redirectToLogin) },
  beforeDestroy () { this.$nuxt.$off("open-login", this.redirectToLogin) },

  methods: {
    redirectToLogin () {
      this.$router.push("/auth/login")
    },
  },
})
