import { wrap } from "../_"
import type { App, CreateAppPayload } from "../../../types/api/car-pass"

const pfx = "/car-pass"

export default (a: NuxtAxiosInstance) => ({
  getMyApps: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/my`,
      { ...cfg })),

  createApp: (payload: CreateAppPayload, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}`, payload,
      { ...cfg })),

  updateApp: (id: App["id"], payload: CreateAppPayload, cfg?: AxiosConfig) => wrap(
    a.$patch(`${pfx}/${id}`, payload,
      { ...cfg })),
})
