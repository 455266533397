import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6355361b = () => interopDefault(import('../pages/alley.vue' /* webpackChunkName: "pages/alley" */))
const _47ea8eae = () => interopDefault(import('../pages/badge.vue' /* webpackChunkName: "pages/badge" */))
const _3c915630 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _a468cc8e = () => interopDefault(import('../pages/exhibition.vue' /* webpackChunkName: "pages/exhibition" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _29a14d62 = () => interopDefault(import('../pages/festival.vue' /* webpackChunkName: "pages/festival" */))
const _4940a3a6 = () => interopDefault(import('../pages/new.vue' /* webpackChunkName: "pages/new" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _7120d68d = () => interopDefault(import('../pages/program/index.vue' /* webpackChunkName: "pages/program/index" */))
const _6b77b3fc = () => interopDefault(import('../pages/results.vue' /* webpackChunkName: "pages/results" */))
const _3429c641 = () => interopDefault(import('../pages/speakers/index.vue' /* webpackChunkName: "pages/speakers/index" */))
const _29f8dfcd = () => interopDefault(import('../pages/user/index.ts' /* webpackChunkName: "pages/user/index" */))
const _7364f08e = () => interopDefault(import('../pages/users.vue' /* webpackChunkName: "pages/users" */))
const _1c75ff9a = () => interopDefault(import('../pages/auth/impersonalize.vue' /* webpackChunkName: "pages/auth/impersonalize" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _23d2ff46 = () => interopDefault(import('../pages/auth/registration.vue' /* webpackChunkName: "pages/auth/registration" */))
const _799a776e = () => interopDefault(import('../pages/user/badge.vue' /* webpackChunkName: "pages/user/badge" */))
const _c4bfe6f0 = () => interopDefault(import('../pages/user/calendar.vue' /* webpackChunkName: "pages/user/calendar" */))
const _78e0b0a2 = () => interopDefault(import('../pages/user/chat.vue' /* webpackChunkName: "pages/user/chat" */))
const _fa9e3b58 = () => interopDefault(import('../pages/user/edit.vue' /* webpackChunkName: "pages/user/edit" */))
const _3d99c344 = () => interopDefault(import('../pages/user/parking.vue' /* webpackChunkName: "pages/user/parking" */))
const _78875b2f = () => interopDefault(import('../pages/user/payment/index.vue' /* webpackChunkName: "pages/user/payment/index" */))
const _2f1fb373 = () => interopDefault(import('../pages/user/profile/index.ts' /* webpackChunkName: "pages/user/profile/index" */))
const _ff7e78ea = () => interopDefault(import('../pages/user/profile/edit.ts' /* webpackChunkName: "pages/user/profile/edit" */))
const _2a6fc7db = () => interopDefault(import('../pages/auth/oauth/_provider.vue' /* webpackChunkName: "pages/auth/oauth/_provider" */))
const _69beb993 = () => interopDefault(import('../pages/news/_alias.vue' /* webpackChunkName: "pages/news/_alias" */))
const _244009bb = () => interopDefault(import('../pages/partners/_alias.vue' /* webpackChunkName: "pages/partners/_alias" */))
const _e09f28b4 = () => interopDefault(import('../pages/program/_alias.vue' /* webpackChunkName: "pages/program/_alias" */))
const _2dc57272 = () => interopDefault(import('../pages/speakers/_alias.vue' /* webpackChunkName: "pages/speakers/_alias" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alley",
    component: _6355361b,
    name: "alley___ru"
  }, {
    path: "/badge",
    component: _47ea8eae,
    name: "badge___ru"
  }, {
    path: "/career",
    component: _3c915630,
    name: "career___ru"
  }, {
    path: "/exhibition",
    component: _a468cc8e,
    name: "exhibition___ru"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq___ru"
  }, {
    path: "/festival",
    component: _29a14d62,
    name: "festival___ru"
  }, {
    path: "/new",
    component: _4940a3a6,
    name: "new___ru"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news___ru"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners___ru"
  }, {
    path: "/program",
    component: _7120d68d,
    name: "program___ru"
  }, {
    path: "/results",
    component: _6b77b3fc,
    name: "results___ru"
  }, {
    path: "/speakers",
    component: _3429c641,
    name: "speakers___ru"
  }, {
    path: "/user",
    component: _29f8dfcd,
    name: "user___ru"
  }, {
    path: "/users",
    component: _7364f08e,
    name: "users___ru"
  }, {
    path: "/auth/impersonalize",
    component: _1c75ff9a,
    name: "auth-impersonalize___ru"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login___ru"
  }, {
    path: "/auth/registration",
    component: _23d2ff46,
    name: "auth-registration___ru"
  }, {
    path: "/user/badge",
    component: _799a776e,
    name: "user-badge___ru"
  }, {
    path: "/user/calendar",
    component: _c4bfe6f0,
    name: "user-calendar___ru"
  }, {
    path: "/user/chat",
    component: _78e0b0a2,
    name: "user-chat___ru"
  }, {
    path: "/user/edit",
    component: _fa9e3b58,
    name: "user-edit___ru"
  }, {
    path: "/user/parking",
    component: _3d99c344,
    name: "user-parking___ru"
  }, {
    path: "/user/payment",
    component: _78875b2f,
    name: "user-payment___ru"
  }, {
    path: "/user/profile",
    component: _2f1fb373,
    name: "user-profile___ru"
  }, {
    path: "/user/profile/edit",
    component: _ff7e78ea,
    name: "user-profile-edit___ru"
  }, {
    path: "/auth/oauth/:provider?",
    component: _2a6fc7db,
    name: "auth-oauth-provider___ru"
  }, {
    path: "/news/:alias",
    component: _69beb993,
    name: "news-alias___ru"
  }, {
    path: "/partners/:alias",
    component: _244009bb,
    name: "partners-alias___ru"
  }, {
    path: "/program/:alias",
    component: _e09f28b4,
    name: "program-alias___ru"
  }, {
    path: "/speakers/:alias",
    component: _2dc57272,
    name: "speakers-alias___ru"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
