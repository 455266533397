
import Vue from "vue"
import { mapGetters } from "vuex"
import type { Person, User } from "@/types/api/person"

const variants = [
  "#a195ff", "#56fda2", "#ff8cfe", "#ffc085", "#9f80ff", "#74d6ff",
] as const
type Variant = typeof variants[number]

const getRandomVariant = (): Variant =>
  variants[Math.floor(Math.random() * variants.length)]

export default Vue.extend({
  inheritAttrs: false,

  props: {
    for: {
      type: Object as VuePropType<Nullable<Person | User>>,
      default: null,
    },
    useFront: { type: Boolean, default: false },
  },

  data: () => ({
    variant: variants[0] as Variant,
  }),

  computed: {
    ...mapGetters("user", { me: "user" }),

    user (): Nullable<Person | User> {
      return this.for || this.me || null
    },

    photoUrl (): string {
      const { user } = this
      if (!user) return ""
      const url = user.photo?.url || ""
      return this.useFront ? user.frontPagePhoto?.url || url : url
    },

    initials (): string {
      const { user } = this
      if (!user) return ""
      return ["firstName", "lastName"]
        .map(k => this.$fmt.localize(user[k]).charAt(0)).join("")
    },
  },

  mounted () {
    this.variant = this.getVariant()
  },

  methods: {
    getVariant (): Variant {
      const { user } = this
      if (!user || !user.id) return getRandomVariant()

      const sum = user.id.split("")
        .map((c: string) => c.charCodeAt(0))
        .reduce((c: number, acc: number) => acc + c, 0)
      return variants[sum % variants.length]
    },
  },
})
