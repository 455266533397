import { onLoad } from "@/utils/_"

export default function ({ isDev }) {
  if (isDev) return

  onLoad(() => {
    const script = document.createElement("script")
    script.text = `
      !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1362814-f1MII"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
    `
    document.body.appendChild(script)
  })
}
