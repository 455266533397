
import Vue from "vue"
import type { Track } from "@/types/api/program"

export default Vue.extend({
  data: () => ({
    tracks: [] as Track[],
    currTrackAlias: "" as Track["hall"]["alias"],
  }),

  async fetch () {
    await this.fetchTracks()
  },

  computed: {
    tracksWithPlayer (): Track[] {
      return this.tracks.filter(t => t.liveData.embedCode)
    },

    currTrack (): Track | null {
      return this.tracksWithPlayer
        .find(t => t.hall.alias === this.currTrackAlias) || null
    },

    embedCode (): string {
      const embedCode = this.currTrack?.liveData.embedCode || ""
      return embedCode.startsWith("<iframe") ? embedCode : ""
    },
  },

  methods: {
    async fetchTracks () {
      const [res, err] = await this.$api.program.getTracks()
      if (err) {
        console.error(err)
      } else {
        this.tracks = this.$api.helpers.getList(res)
        this.currTrackAlias = this.tracksWithPlayer[0]?.hall.alias || ""
      }
    },
  },
})
