import { render, staticRenderFns } from "./LandingMap.vue?vue&type=template&id=860c9e7a&"
var script = {}
import style0 from "./LandingMap.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHeading: require('/app/components/landing/LandingHeading.vue').default,LandingMapMap: require('/app/components/landing/map/LandingMapMap.vue').default})
