import { wrap } from "../_"

interface Message {
  text: string
  replyTo?: string
}

const pfx = "/chat"

export default (a: NuxtAxiosInstance) => ({
  banUser: (userId: string, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/user/${userId}/ban`, {},
      { ...cfg })),

  public: {
    sendMessage: (chatId: string, message: Message, cfg?: AxiosConfig) =>
      wrap(
        a.$post(`${pfx}/public/${chatId}/message`, message,
          { ...cfg })),

    getMessages: (chatId: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/public/${chatId}/message`,
        { ...cfg })),

    deleteMessage: (chatId: string, messageId: string, cfg?: AxiosConfig) =>
      wrap(
        a.$delete(`${pfx}/public/${chatId}/message/${messageId}`,
          { ...cfg })),

    pinMessage: (chatId: string, messageId: string, cfg?: AxiosConfig) =>
      wrap(
        a.$post(`${pfx}/public/${chatId}/pin`, { message: messageId },
          { ...cfg })),

    getPinnedMessage: (chatId: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/public/${chatId}/pin`,
        { ...cfg })),

    unpinMessage: (chatId: string, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/public/${chatId}/unpin`, {},
        { ...cfg })),
  },

  private: {
    createChat: (userId: string, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/private`, { participant: userId },
        { ...cfg })),

    getTicket: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/private/ticket`,
        { ...cfg })),

    getChats: (cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/private`,
        { ...cfg })),

    sendMessage: (chatId: string, message: Message, cfg?: AxiosConfig) =>
      wrap(
        a.$post(`${pfx}/private/${chatId}/message`, message,
          { ...cfg })),

    getMessages: (chatId: string, cfg?: AxiosConfig) => wrap(
      a.$get(`${pfx}/private/${chatId}/message`,
        { ...cfg })),

    markChatRead: (chatId: string, cfg?: AxiosConfig) => wrap(
      a.$post(`${pfx}/private/${chatId}/read`, {},
        { ...cfg })),
  },
})
