
import Vue from "vue"
import random from "lodash/random"
import FontFaceObserver from "fontfaceobserver"
import { mapState, mapActions } from "vuex"
import { World, Body, Plane, Circle } from "p2"
import { gsap } from "gsap"
import type { Application } from "pixi.js-legacy"
import type { Tag } from "@/types/api/tag"
import breakpoints from "@/assets/style/_breakpoints.scss"

type Unwrap<T> = T extends PromiseLike<infer U> ? U : T;
let PIXI: Unwrap<typeof import("pixi.js-legacy")>

export default Vue.extend({
  data: () => ({
    isVisible: false,
    loading: true,
    app: null as Nullable<Application>,
    animate: null as Nullable<FrameRequestCallback>,
    animationReq: null as Nullable<ReturnType<typeof requestAnimationFrame>>,
  }),

  computed: {
    ...mapState("app", ["tags"]),
    programTags (): Tag[] {
      return this.tags.filter((t: Tag) => t.group.alias === "program")
    },
  },

  async mounted () {
    await this.fetchTags()
    if (!this.programTags.length) return
    try {
      PIXI = await import("pixi.js-legacy")
      const font = new FontFaceObserver("GothamPro", { weight: 600 })
      await font.load(null, 5 * 1000)
      this.loading = false
      await this.$nextTick()
      this.init()
    } catch {}
  },
  beforeDestroy () {
    this.app?.destroy(true, { children: true })
    const { animationReq } = this
    if (animationReq) cancelAnimationFrame(animationReq)
  },

  methods: {
    ...mapActions("app", { fetchTags: "FETCH_TAGS" }),

    init () {
      const el = this.$refs.circles as HTMLElement
      if (!el) return

      // Константы
      const isMobile = window.innerWidth < breakpoints.sm
      const COLORS = [0x138F34, 0xFAD914, 0xF6110C, 0xB01787]
      const HOVER_SCALE = 1.5
      const [WIDTH, HEIGHT] = isMobile ? [480, 720] : [1460, 688]
      const [MIN_R, MAX_R] = isMobile ? [49, 76] : [71, 127]
      const PADDING = (HOVER_SCALE - 1) * MAX_R

      const app = this.app = new PIXI.Application({
        width: WIDTH,
        height: HEIGHT,
        antialias: !isMobile,
        resolution: isMobile ? 1 : 2,
        transparent: true,
      })
      el.appendChild(app.view)
      const world = new World({ gravity: [0, 0] })

      // Границы мира
      const stageBody = new Body()
      world.addBody(stageBody)
      const floor = new Plane()
      stageBody.addShape(floor, [PADDING, PADDING])
      const roof = new Plane()
      stageBody.addShape(roof, [PADDING, HEIGHT - PADDING], Math.PI)
      const wallLeft = new Plane()
      stageBody.addShape(wallLeft, [PADDING, PADDING], -(Math.PI / 2))
      const wallRight = new Plane()
      stageBody.addShape(wallRight, [WIDTH - PADDING, PADDING], Math.PI / 2)

      const circles = this.programTags.map((t: Tag, i: number) => {
        const radius = random(MIN_R, MAX_R)
        const bg = COLORS[i % COLORS.length]
        const fg = [0xFAD914].includes(bg) ? "#000" : "#fff"

        // Контейнер
        const el = new PIXI.Container()
        app.stage.addChild(el)

        // Круг
        const circle = new PIXI.Graphics()
        circle.beginFill(bg)
        circle.drawCircle(0, 0, radius)
        circle.endFill()
        el.addChild(circle)

        // Текст
        const textStyle = new PIXI.TextStyle({
          fontFamily: "GothamPro",
          fontSize: 0.14 * radius,
          fontWeight: "600",
          fill: fg,
          align: "center",
          wordWrap: true,
          wordWrapWidth: 1.8 * radius,
          breakWords: true,
        })
        const text = new PIXI.Text(
          this.$fmt.localize(t.title).toUpperCase(), textStyle)
        text.anchor.x = 0.5
        text.anchor.y = 0.5
        el.addChild(text)

        // Физика
        const shape = new Circle({ radius })
        const body = new Body({
          mass: radius / MAX_R,
          // mass: 0.001,
          position: [random(WIDTH), random(HEIGHT)],
          // angularVelocity: 0,
          fixedRotation: true,
        })
        body.addShape(shape)
        world.addBody(body)

        // События
        el.interactive = true
        el.buttonMode = true
        el
          .on("pointerover", () => {
            gsap.to(el.scale, { x: HOVER_SCALE, y: HOVER_SCALE })
            gsap.to(shape, { radius: HOVER_SCALE * radius })
          })
          .on("pointerout", () => {
            gsap.to(el.scale, { x: 1, y: 1 })
            gsap.to(shape, { radius })
          })
          // .on("pointerup", () => { this.$router.push(`/live/${h.alias}`) })

        return {
          update () {
            // body.applyForce([-body.position[0] / 1000, -body.position[1] / 1000])
            el.x = body.position[0]
            el.y = body.position[1]
          },
        }
      })

      this.animate = () => {
        world.step(1 / 60)
        circles.forEach((c: typeof circles[number]) => c.update())
        app.render()
        const { animate } = this
        if (animate) this.animationReq = requestAnimationFrame(animate)
      }
    },

    onVisibilityChanged (visible: boolean) {
      if (visible) {
        const { animate } = this
        if (animate) requestAnimationFrame(animate)
      } else {
        const { animationReq } = this
        if (animationReq) cancelAnimationFrame(animationReq)
      }
    },
  },
})
