import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fcb3056&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingStreamNew: require('/app/components/landing/LandingStreamNew.vue').default,LandingHeroNew: require('/app/components/landing/LandingHeroNew.vue').default,LandingWhatIs: require('/app/components/landing/LandingWhatIs.vue').default,LandingNews: require('/app/components/landing/LandingNews.vue').default,LandingThemes: require('/app/components/landing/LandingThemes.vue').default,LandingSpeakers: require('/app/components/landing/LandingSpeakers.vue').default,LandingCompanies: require('/app/components/landing/LandingCompanies.vue').default,LandingMap: require('/app/components/landing/map/LandingMap.vue').default,LandingPartners: require('/app/components/landing/LandingPartners.vue').default})
