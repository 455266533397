import set from "lodash/set"
import { parseRes, parseErr } from "./api/_"

const plugin: NuxtPlugin = ({ $axios, app, $sentry }) => {
  $axios.onRequest((req) => {
    // Устанавливаем локаль
    if (!req.params) req.params = {}
    req.params.lang = app.i18n.locale
  })

  // Парсим ответ
  $axios.onResponse((res) => { res.data = parseRes(res) })

  $axios.onResponseError((err) => {
    // Репортим в Sentry
    let [req, res] = [{}, {}]
    try {
      req = JSON.parse(err.config.data)
      res = JSON.parse(JSON.stringify(err.response?.data || {}))
    } catch {}
    if (Object.keys(req).length || Object.keys(res).length) {
      $sentry.setContext("request", req)
      $sentry.setContext("response", res)
      $sentry.captureException(err)
    }

    // Унифицируем сообщение об ошибке
    set(err, "response.data", parseErr(app.i18n)(err))
  })
}
export default plugin

declare module "axios" { interface AxiosRequestConfig { cache?: boolean }}
