
import Vue from "vue"

export default Vue.extend({
  layoutSettings: { fullWidth: true },

  // validate ({ store }) {
  //   return store.getters["user/isAdmin"]
  // },

  data: () => ({
    isMounted: false,
  }),

  mounted () {
    this.isMounted = true
  },
})
