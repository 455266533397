
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import type { Person } from "@/types/api/person"

export default Vue.extend({
  data: () => ({
    speakers: [] as Person[],
  }),

  async fetch () {
    await this.fetchSpeakers()
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 30,
        slidesPerView: "auto",
      }
    },
  },

  methods: {
    async fetchSpeakers () {
      const [res, err] = await this.$api.person.getSpeakers({
        params: {
          "filter[front]": true,
          "sort[front]": "asc",
        },
      })
      if (err) return console.error(err)
      this.speakers = this.$api.helpers.getList(res)
    },

    getImg (s: Person): string {
      return s.frontPagePhoto?.url || s.photo?.url || ""
    },
  },
})
