import { render, staticRenderFns } from "./LandingSpeakers.vue?vue&type=template&id=fda825d4&"
import script from "./LandingSpeakers.vue?vue&type=script&lang=ts&"
export * from "./LandingSpeakers.vue?vue&type=script&lang=ts&"
import style0 from "./LandingSpeakers.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHeading: require('/app/components/landing/LandingHeading.vue').default,AppSwiper: require('/app/components/app/AppSwiper.vue').default})
