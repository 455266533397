export const actions = {
  async nuxtServerInit ({ commit, dispatch }) {
    const pricesChangeDate = Date.UTC(2023, 3, 15, 21) // 2023-04-16T00:00:00+03:00
    const pricesChanged = Date.now() >= pricesChangeDate

    commit("app/SET_PRICES_CHANGED", pricesChanged)

    await Promise.all([
      dispatch("app/FETCH_EVENT"),
      dispatch("app/FETCH_MENU"),
      // dispatch("live/FETCH_HALLS"),
    ])
  },
}
