import Vue from "vue"
import shader from "shader"
import invert from "invert-color"
import { lookup as mimeLookup } from "mime-types"
import { mapState, mapGetters } from "vuex"
import type { MetaInfo, LinkPropertyHref } from "vue-meta"
import { getOgImgMeta } from "@/utils/meta"

const rgb = (hex: string) => invert.asRgbArray(invert(hex))

export default Vue.extend({
  head () {
    const head: MetaInfo = {
      htmlAttrs: { style: this.rootVars },
      link: [this.favicon],
      title: this.getTitle(this.$t(this.settings.titleToken)),
    }

    head.meta = []
    const { description, ogImg } = this
    if (description) head.meta.push({
      hid: "description", name: "description", content: description,
    })
    if (ogImg) head.meta = head.meta.concat(getOgImgMeta(ogImg))

    return head
  },

  computed: {
    ...mapState("app", ["event"]),
    ...mapGetters("app", ["getTitle", "description"]),

    rootVars (): string {
      const theme = this.event?.theme || {}

      const fg = "#333333"
      const gray1 = "#f8f9fa"
      const gray2 = "#dde2e5"
      const gray3 = "#acb5bd"
      const gray4 = "#495057"
      const shadow = "#000"
      const bg = "#f1f1f1" // theme.bgColor || "#fff"
      const primary = "#b01787" // theme.primaryColor || "#0537ff"

      const isDark = theme.darkTheme
      return `
        --color-fg: ${isDark ? invert(fg) : fg};
        --color-gray-1: ${isDark ? invert(gray1) : gray1};
        --color-gray-2: ${isDark ? invert(gray2) : gray2};
        --color-gray-3: ${isDark ? invert(gray3) : gray3};
        --color-gray-4: ${isDark ? invert(gray4) : gray4};
        --color-shadow-rgb: ${isDark
    ? invert.asRgbArray(shadow)
    : invert.asRgbArray(invert(shadow))};
        --color-bg: ${isDark ? invert(bg) : bg};
        --color-bg-invert: ${isDark ? bg : invert(bg)};
        --color-primary: ${primary};
        --color-primary-rgb: ${rgb(primary)};
        --color-primary-hover: ${shader(primary, -0.2)};
        --color-on-primary: ${invert(primary, true)};
        --color-primary-on-bg: ${invert(primary, {
    black: isDark ? primary : "#000",
    white: isDark ? "#fff" : primary,
  })};
      `
    },

    favicon (): LinkPropertyHref {
      const href = this.event?.metadata?.favicon?.url
      const type = mimeLookup(href) || undefined
      return { rel: "icon", type, href }
    },

    ogImg (): string { return this.event?.metadata?.image?.url || "" },

    settings (): Record<string, any> {
      const component = this.$route.matched[0]?.components.default as any
      if (!component) return {}
      return component.options?.layoutSettings || component.layoutSettings || {}
    },
  },
})
