var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['content', _vm.$style.content]},[(!_vm.noBg)?_c('img',{class:_vm.$style.figure,attrs:{"src":"/img/landing/map-bg.svg","loading":"lazy","alt":""}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.onVisibilityChanged),expression:"onVisibilityChanged"}],class:_vm.$style.map},[_c('img',{class:_vm.$style.mapBorder,attrs:{"src":"/img/landing/map-border.jpg","loading":"lazy","alt":"","width":"1600","height":"976"}}),_vm._v(" "),_c('div',{class:[_vm.$style.mapContent, ( _obj = {}, _obj[_vm.$style.mapContentVisible] = _vm.mapVisible, _obj[_vm.$style.mapContentNavItemHovered] = _vm.isNavItemHovered, _obj[_vm.$style.mapContentFull] = _vm.noBg, _obj )]},[_vm._l((_vm.points),function(p,i){
        var _obj;
return _c('div',{key:i,class:[_vm.$style.point, ( _obj = {}, _obj[_vm.$style.pointActive] = p.active, _obj )],style:({ left: ((p.x) + "%"), top: ((p.y) + "%") }),attrs:{"data-point":""}},[_c('div',{class:_vm.$style.pointPin},[_vm._v("\n            "+_vm._s(p.n)+"\n          ")]),_vm._v(" "),(p.show)?_c('div',{class:_vm.$style.pointTitle},[_vm._v("\n            "+_vm._s(p.title)+"\n          ")]):(p.icon)?_c('div',{class:_vm.$style.pointTitle},[_c('app-icon',{class:_vm.$style.pointIcon,attrs:{"name":p.icon}})],1):_vm._e()])}),_vm._v(" "),_c('div',{class:[_vm.$style.mapNav, _vm.$style.mapNavDesktop]},_vm._l((_vm.points.filter(function (p) { return p.n; })),function(p,i){
        var _obj;
return _c('button',{key:i,class:[_vm.$style.point, _vm.$style.mapNavItem, ( _obj = {}, _obj[_vm.$style.mapNavItemActive] = p.active, _obj )],attrs:{"type":"button"},on:{"mouseover":function($event){return _vm.onNavItemMouseOver(+i)},"mouseleave":function($event){return _vm.onNavItemMouseLeave(+i)},"focus":function($event){return _vm.onNavItemMouseOver(+i)},"blur":function($event){return _vm.onNavItemMouseLeave(+i)}}},[_c('div',{class:_vm.$style.pointPin},[_vm._v("\n              "+_vm._s(p.n)+"\n            ")]),_vm._v(" "),_c('div',{class:_vm.$style.mapNavItemDesc},[_vm._v("\n              "+_vm._s(p.desc || p.title)+"\n            ")])])}),0)],2)])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{class:[_vm.$style.mapNav, _vm.$style.mapNavMobile]},_vm._l((_vm.points.filter(function (p) { return p.n; })),function(p,i){
            var _obj;
return _c('button',{key:i,class:[_vm.$style.point, _vm.$style.mapNavItem, ( _obj = {}, _obj[_vm.$style.mapNavItemActive] = p.active, _obj )],attrs:{"type":"button"},on:{"mouseover":function($event){return _vm.onNavItemMouseOver(+i)},"mouseleave":function($event){return _vm.onNavItemMouseLeave(+i)},"focus":function($event){return _vm.onNavItemMouseOver(+i)},"blur":function($event){return _vm.onNavItemMouseLeave(+i)}}},[_c('div',{class:_vm.$style.pointPin},[_vm._v("\n          "+_vm._s(p.n)+"\n        ")]),_vm._v(" "),_c('div',{class:_vm.$style.mapNavItemDesc},[_vm._v("\n          "+_vm._s(p.desc || p.title)+"\n        ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }