
import Vue from "vue"
import type { PartnerGroup, Partner } from "@/types/api/partner"

interface Group extends PartnerGroup { items: Partner[] }

export default Vue.extend({
  props: {
    front: { type: Boolean, default: false },
    groups: {
      type: Array as VuePropType<PartnerGroup["alias"][]>,
      default: () => ([]),
    },
    noStatuses: { type: Boolean, default: false },
    noGroupTitles: { type: Boolean, default: false },
    withHeading: { type: Boolean, default: false },
  },

  data: () => ({
    allGroups: [] as PartnerGroup[],
    partners: [] as Partner[],
  }),

  async fetch () {
    await Promise.all([
      this.fetchAllGroups(),
      this.fetchPartners(),
    ])
  },

  computed: {
    filteredGroups (): Group[] {
      const { groups, partners } = this
      const filteredGroups: Group[] = []

      this.allGroups.forEach((g) => {
        if (groups.length && !groups.includes(g.alias)) return
        const items = partners
          .filter(p => p.groups.some(pg => pg.alias === g.alias))
          .map(p => ({ ...p, pageVisible: false }))
        if (items.length) filteredGroups.push({ ...g, items })
      })

      return filteredGroups
    },
  },

  methods: {
    async fetchAllGroups () {
      const [res, err] = await this.$api.partner.getPartnersGroups({
        params: {
          "filter[front]": this.front || undefined,
          "sort[list]": "asc",
        },
      })
      if (err) return console.error(err)
      this.allGroups = this.$api.helpers.getList(res)
    },

    async fetchPartners () {
      const [res, err] = await this.$api.partner.getPartners({
        params: {
          "filter[front]": this.front || undefined,
          "sort[list]": "asc",
        },
      })
      if (err) return console.error(err)
      this.partners = this.$api.helpers.getList(res)
    },

    getLogo (partner: Partner): string {
      return partner?.logo?.ru?.url || ""
    },
  },
})
