
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import type { Partner, FullPartner } from "@/types/api/partner"

export default Vue.extend({
  data: () => ({
    partners: [] as Partner[],
    isDialogOpen: false,
    isDialogLoading: false,
    dialogCompany: null as Nullable<FullPartner>,
  }),

  async fetch () {
    await this.fetchPartners()
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 32,
        slidesPerView: "auto",
      }
    },
  },

  methods: {
    async fetchPartners () {
      const [res, err] = await this.$api.partner.getPartners({
        params: { "sort[list]": "asc", "filter[group]": "member-companies" },
      })
      if (err) return console.error(err)
      this.partners = this.$api.helpers.getList(res)
    },

    getLogo (partner: Partner | FullPartner): string {
      return partner?.logo?.ru?.url || ""
    },

    cleanUrl (url: string) {
      return url.replace(/^https?:\/\//, "")
    },

    async openDialog (e: MouseEvent, company: Partner) {
      if (company.pageVisible) e.preventDefault()
      else return
      this.isDialogLoading = true
      this.isDialogOpen = true
      const [res, err] = await this.$api.partner.getPartnerData(company.alias)
      if (err) return console.error(err)
      this.dialogCompany = res
      this.isDialogLoading = false
    },
  },
})
