import { wrap } from "../_"

interface UseCouponData {
  ownerId: number | string
  productId: number
  couponCode: string
}

interface AddItemData {
  ownerId: number | string
  product: number
  room?: string
  // quick: boolean
}

const pfx = "/basket"

export default (a: NuxtAxiosInstance) => ({
  useCoupon: (data: UseCouponData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/coupon`, data,
      { ...cfg })),

  getBills: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/bill`,
      { ...cfg })),

  getPaymentUrl: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/payment/url`,
      { ...cfg })),

  getUsers: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/user`,
      { ...cfg })),

  getProducts: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/product`,
      { ...cfg })),

  getItems: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/item`,
      { ...cfg })),

  addItem: (data: AddItemData, cfg?: AxiosConfig) => wrap(
    a.$post(`${pfx}/item`, { quick: false, ...data },
      { ...cfg })),

  deleteItem: (itemId: number, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/item/${itemId}`,
      { ...cfg })),

  deleteBill: (billId: number, cfg?: AxiosConfig) => wrap(
    a.$delete(`${pfx}/bill/${billId}`,
      { ...cfg })),

  getPayUrl: (cfg?: AxiosConfig) => wrap(
    a.$get(`${pfx}/payment/url`,
      { ...cfg }),
  ),

  getRooms: (productId?: number, cfg?: AxiosConfig) => wrap(
    a.$get("/rif/room",
      { params: { "filter[product]": productId, ...cfg } },
    )),
})
