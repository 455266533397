
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"

export default Vue.extend({
  computed: {
    ...mapGetters("user", ["isAdmin"]),
  },

  methods: {
    ...mapActions("user", { logout: "LOGOUT" }),
  },
})
