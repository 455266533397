import type Vue from "vue"
import ReconnectingWebSocket, { UrlProvider } from "reconnecting-websocket"
import type api from "../plugins/api/modules/chat"

// https://github.com/pladaria/reconnecting-websocket#available-options
const SOCKET_OPTS = {
  maxReconnectionDelay: 1000 * 60 * 10,
  startClosed: true,
}
export const MESSAGES_LIMIT = 16

type Api = ReturnType<typeof api>

type Command = "join" | "leave"
type Event = "pinnedMessage" | "unpinnedMessage" | "deletedMessage" |
"newMessage"
type Listener = Command | Event

type GetMessagesOpts = { before?: string, after?: string, excludedID?: string }

class Chat {
  private readonly topic: string
  private socket: null | ReconnectingWebSocket = null
  readonly on: Partial<Record<Listener, Function>> = {}
  readonly url: string
  readonly id: string

  constructor (url: string, topic: string, id: string) {
    this.url = url
    this.topic = topic
    this.id = id
  }

  private send (command: Command) {
    this.socket?.send(JSON.stringify({ command, topics: [this.topic] }))
  }

  join (urlProvider: UrlProvider = this.url) {
    const socket = new ReconnectingWebSocket(urlProvider, [], SOCKET_OPTS)

    socket.addEventListener("open", () => { this.send("join") })
    socket.addEventListener("close", () => { this.on.leave?.() })
    socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data)
      const { topic } = data
      let { payload } = data
      let listener
      if (topic.startsWith("#")) return this.leave()
      if (topic.startsWith("@")) {
        listener = topic.slice(1)
      } else if (payload) {
        payload = JSON.parse(payload)
        listener = payload.event
      }
      if (listener) this.on[listener as Listener]?.(payload)
    })
    socket.addEventListener("error", () => {
      console.error("Произошла ошибка")
    })

    this.socket = socket
    socket.reconnect()
  }

  leave () { this.socket?.close() }
}

export class ChatPublic extends Chat {
  private readonly api: Api

  constructor (api: Api, url: string, id: string) {
    super(url, `chat.${id}`, id)
    this.api = api
  }

  async sendMessage (message: Parameters<Api["public"]["sendMessage"]>[1]) {
    const [res, err] = await this.api.public.sendMessage(this.id, message)
    return err ? console.error(err) : res
  }

  async getMessages (opts: GetMessagesOpts = {}) {
    const [res, err] = await this.api.public.getMessages(this.id, {
      params: {
        "filter[before]": opts.before,
        "filter[after]": opts.after,
        "filter[excludedID]": opts.excludedID,
        "sort[creationTime]": opts.after ? "desc" : "asc",
        "paginator[limit]": MESSAGES_LIMIT,
      },
    })
    return err ? console.error(err) : res
  }

  async deleteMessage (messageId: string) {
    const [, err] = await this.api.public.deleteMessage(this.id, messageId)
    if (err) console.error(err)
  }

  async pinMessage (messageId: string) {
    const [, err] = await this.api.public.pinMessage(this.id, messageId)
    if (err) console.error(err)
  }

  async getPinnedMessage () {
    const [res, err] = await this.api.public.getPinnedMessage(this.id)
    return err ? console.error(err) : res
  }

  async unpinMessage () {
    const [, err] = await this.api.public.unpinMessage(this.id)
    if (err) console.error(err)
  }

  async banUser (userId: string) {
    const [, err] = await this.api.banUser(userId)
    if (err) console.error(err)
  }
}

export class ChatPrivate extends Chat {
  private readonly api: Api

  constructor (api: Api, url: string, id: string) {
    super(url, `user.${id}`, id)
    this.api = api
  }

  join () {
    super.join(async () => {
      const [res, err] = await this.api.private.getTicket()
      if (err) {
        console.error(err)
        return ""
      } else {
        return `${this.url}?ticket=${res.ticket}`
      }
    })
  }

  async sendMessage (
    chatId: string, message: Parameters<Api["private"]["sendMessage"]>[1],
  ) {
    const [res, err] = await this.api.private.sendMessage(chatId, message)
    return err ? console.error(err) : res
  }

  async getMessages (chatId: string, opts: GetMessagesOpts = {}) {
    const [res, err] = await this.api.private.getMessages(chatId, {
      params: {
        "filter[before]": opts.before,
        "filter[after]": opts.after,
        "filter[excludedID]": opts.excludedID,
        "sort[creationTime]": opts.after ? "desc" : "asc",
        "paginator[limit]": MESSAGES_LIMIT,
      },
    })
    return err ? console.error(err) : res
  }

  async markRead (chatId: string) {
    const [, err] = await this.api.private.markChatRead(chatId)
    return err ? console.error(err) : true
  }
}

export const parseCtx = (ctx: NuxtContext | Vue) => ({
  api: ctx.$api.chat,
  url: ctx.$config.chatUrl,
  userId: ctx.$auth.user?.id as string,
})
